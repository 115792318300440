import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";

const SecondaryTextButton = ({ children, className, to }) => {
  return (
    <Link
      to={to}
      className={classNames(
        "font-normal text-base px-6 py-2 rounded inline-block tracking-wide no-underline shadow transition-colors",
        "text-black bg-grey hover:bg-grey-dark",
        className
      )}
    >
      {children}
    </Link>
  );
};
export default SecondaryTextButton;

// bg-green-darkest
// bg-yellow-darker
// bg-yellow-darkest
// bg-teal-darkest
